import { graphql } from 'gatsby';
import React from 'react';
import Hero from '../components/chapter/Hero';
import ImageLayout from '../components/chapter/ImageLayout';
import NextChapter from '../components/chapter/NextChapter';
import Text from '../components/chapter/Text';
import Layout from '../Layout';

const VisitPage = ({ data }) => {
  const { title, color, components, hero, lede, subtitle, title_svg } =
    data.strapiVisitPages;
  return (
    <Layout title={title} seoImage={hero}>
      <Hero
        title={title}
        subtitle={subtitle}
        titleSvg={title_svg}
        illustration={hero}
        lede={lede}
        color={color}
      />
      {components?.map(
        (
          {
            strapi_component,
            //attribution, TODO: implement more componence once data
            body,
            collage,
            image_credit,
            limit_height,
            //mask,
            layout,
          },
          index
        ) => {
          return {
            'chapter.image': (
              <ImageLayout
                key={index}
                imageCredit={image_credit}
                limitHeight={limit_height}
                layout={layout}
                color={color}
              />
            ),
            'chapter.text': (
              <Text body={body} key={index} color={color} collage={collage} />
            ),
          }[strapi_component];
        }
      )}
      <NextChapter
        next={data.strapiChapters}
        color={{ accent: data.strapiChapters.color.accent, theme: color.theme }}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    strapiVisitPages(slug: { eq: $slug }) {
      title
      subtitle
      lede
      title_svg {
        localFile {
          svgData
        }
      }
      hero {
        alternativeText
        width
        height
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 60)
            seo: gatsbyImageData(layout: FIXED, width: 1200)
          }
        }
      }
      color {
        accent
        theme
      }
      components {
        strapi_component
        body
        collage {
          layout
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 60
                  sizes: "20.5vw"
                  breakpoints: [500, 750, 1080, 1366]
                )
              }
            }
          }
          brightness
          contrast
        }
        image_credit {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 60)
              }
            }
          }
          credit
          caption
          brightness
          contrast
        }
        layout
        limit_height
      }
    }
    strapiChapters(order: { eq: 310 }) {
      color {
        accent
      }
      preview_img {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 60)
          }
        }
      }
      slug
      title
      summary
    }
  }
`;

export default VisitPage;
